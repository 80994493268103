@import "colors";
@import "utils";
@import "mixins";

#report-analysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  .analysis {
    &-header {
      display: flex;
      flex-direction: column;
      z-index: 2;
      width: 100%;
      padding-bottom: 0;

      &-title-text {
        position: relative;
        min-width: 300px;

        .text-label {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          &:hover {
            border-bottom: 4px dotted #969696;
          }
        }
      }

      &-title-input {
        width: 100%;
        min-width: 300px;
        position: absolute;
        top: -3px;
        left: 0;
      }

      .ant-page-header {
        padding: 16px 0;

        &-heading {
          align-items: center;

          &-tags {
            display: flex;
            align-items: center;

            & > * {
              margin-right: 3px;
            }
          }

          &-extra {
            display: flex;
            align-items: center;

            & > * {
              margin-left: 3px;
              white-space: unset;
            }
          }
        }
      }

      .ant-page-header-heading-left {
        width: 100%;
      }

      .mark-completed-btn {
        background-color: $BUTTON_RED;
        border-color: transparent;
        &:hover {
          border-color: $BUTTON_RED;
        }
      }

      .status-btn {
        border-color: transparent;
        .current-status {
          display: inline;
        }

        .next-status {
          display: none;
        }
      }

      .status-btn:hover {
        background-color: #fef0c7;
        color: #b54708 !important;

        .current-status {
          display: none;
        }

        .next-status {
          display: inline;
        }
      }

      .status-btn.complete-btn:hover {
        background-color: #d1fadf;
        color: #027a48 !important;
      }

      #analysis-options-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
      }
      #indicators-bar {
        margin-bottom: 10px;
      }

      .all-relationship-options {
        display: inline-flex;
        align-items: center;
        padding: 10px;
        padding-right: 0;
        width: fit-content;
        background-color: $APP_FOREGROUND;
        border: 1px solid transparent;
        border-color: #d9d9d9;
        border-radius: 15px;
        font-size: 14px;
        height: 24px;
        overflow: hidden;
        .ant-radio,
        .ant-radio-wrapper,
        .ant-radio-button-wrapper {
          font-size: 11px;
        }
      }

      .entity-indicators {
        list-style: none;
        margin: 0 0 0 -40px;
        li {
          float: left;
          margin-right: 10px;
          display: flex;
          align-items: center;

          .color-box {
            border: 1px solid #ccc;
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
        }
      }
    }

    &-body {
      display: flex;
      height: 100%;

      @keyframes focus-entity {
        0% {
          background-color: red;
        }
        25% {
          background-color: yellow;
        }
        50% {
          background-color: blue;
        }
        100% {
          background-color: green;
        }
      }
      .focus-text {
        background-color: black;
        border: #666 dotted 2px;
        animation-name: focus-entity;
        animation-duration: 16s;
      }
    }

    &-panel {
      flex: 1;
      position: relative;
      background-color: #ffffff;
      margin-right: 16px;
      border-radius: 5px;
      box-shadow: 0 1px 3px #ccc;
    }

    &-annotation {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $APP_FOREGROUND;
      padding: 15px;
      overflow-y: auto;
    }

    &-sentence {
      &:hover {
        background-color: $SENTENCE_HIGHLIGHT;
      }
    }

    &-status {
      background-color: $SENTENCE_HIGHLIGHT;
      &:hover {
        background-color: $SENTENCE_HIGHLIGHT;
      }
    }

    &-image {
      display: block;
      max-height: 700px;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      max-width: 90%;
    }

    &-groups {
      width: 352px;
      min-width: 200px;
      padding: 0px;
      border-radius: 5px;
      border: none;
      overflow: auto;
      .ant-list-item {
        width: 100%;
        padding: 0;

        .ant-collapse {
          font-size: 12px;
          width: 100%;
        }

        .technique-item {
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          &-name {
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 5px;
          }

          &-action {
            display: flex;
          }
          &-detail {
            display: flex;
            margin-bottom: 7px;
            & > span {
              color: #969696;
              font-weight: bold;
              margin-right: 10px;
            }
            div,
            li {
              a {
                text-decoration: none;
                color: inherit;
                &:hover {
                  background-color: $SENTENCE_HIGHLIGHT;
                }
              }
            }
          }
        }
        button.accept-technique,
        button.reject-technique {
          color: #ffffff;
          font-size: 12px;
          padding: 0 3px;
          &:hover,
          &:active,
          &:focus {
            border: none;
          }
        }
        button.accept-technique {
          background-color: $BUTTON_GREEN;
          margin-right: 2px;
          &.all {
            background-color: #ffffff;
            color: $BUTTON_GREEN;
            border: 1px solid $BUTTON_GREEN;
            font-weight: bold;
          }
        }
        button.reject-technique {
          background-color: $BUTTON_RED;
          &.all {
            background-color: #ffffff;
            color: $BUTTON_RED;
            border: 1px solid $BUTTON_RED;
          }
        }
        button.undo-confirmed-technique {
          background-color: $BUTTON_BLUE;
          color: white;
          &.all {
            background-color: #ffffff;
            color: $BUTTON_BLUE;
            border: 1px solid $BUTTON_BLUE;
          }
        }
        button.undo-rejected-technique {
          background-color: $BUTTON_BLUE;
          color: white;
          &.all {
            background-color: #ffffff;
            color: $BUTTON_BLUE;
            border: 1px solid $BUTTON_BLUE;
          }
        }
      }

      .ant-tag {
        font-size: 14px;
      }
    }
  }

  .entity-group-collapse-item.ant-collapse-item {
    .ant-collapse-header,
    .ant-collapse-content-box {
      margin-right: 0;
      padding-right: 0;
    }
  }

  .tag-red {
    color: red;
  }
  .tag-green {
    color: green;
  }
  .tag-orange {
    color: orange;
  }

  .analysis-table {
    overflow-x: auto;
    margin: 10px 0;
    table {
      width: 100%;
      td,
      th {
        border: 1px solid #c7c7c9bb;
        padding: 5px;
        min-width: 70px;
      }
    }
  }

  span.remove-annotation {
    display: none;
    color: $BUTTON_RED;
    font-size: 10px;
    position: absolute;
    top: -10px;
    right: -5px;
    border-radius: 50%;
    .anticon {
      background-color: #ffffff;
      display: inline;
    }
  }
}
.CollapseHeader-count {
  color: #0000004d;
}
.collapsehead {
  .ant-collapse-header {
    background-color: white;
    padding-left: 1rem !important;
  }
}
.collapsehead > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.p-16 {
  padding: 16px !important;
}
.graycolor {
  color: #ccc !important;
}

.entity-status-tooltip {
  padding-bottom: 0;
  border-radius: 8px;
  .ant-tooltip-inner {
    padding: 3px 12px;
    border-radius: 8px;
    min-height: auto;
    background-color: #666;
    color: white;
  }
  .entity-status {
    span:nth-child(1) {
      margin-right: 3px;
    }
  }
}
.entity-relationships {
  .related-entity-section {
    width: 100%;
    overflow: hidden;
    padding: 10px;
  }
  .related-entity {
    height: 25px;
    width: calc(100% - 35px);
    padding: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
  }
  .entity-type {
    height: 15px;
    background: #ffffff;
    padding: 2px 4px;
    margin-left: 4px;
    font-size: 10px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    white-space: nowrap;
    color: $APP_BLACK;
    font-weight: normal;
  }
  .entity-text {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.related {
      font-size: 11px;
    }
  }
}

.entity-list-item {
  border-bottom: 1px solid #eaeaead9;
  padding: 0 10px;
}
.confidence-score-wrapper {
  height: 39px;
  display: flex;
  & > div {
    width: 240px;
  }
  .ant-menu-submenu-title {
    height: auto !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    padding: 0px 16px !important;
    .confidence-score-wrapper-lable {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600 !important;
      line-height: 20px;
      text-align: left;
    }
    i.ant-menu-submenu-arrow {
      display: none;
    }
    .entity-subtype-wrapper {
      width: 174px;
      display: flex;
      max-height: 55px;
      align-items: center;
      font-size: 12px;
      white-space: normal;
      & > span {
        margin-right: 5px;
      }
      .entity-subtype-text-group {
        width: 100%;
        margin-left: 1rem;
        .entity-subtype-title {
          font-weight: 600;
          font-size: 14px;
          margin-right: 5px;
          display: inline-block;
        }
        .entity-subtype {
          text-transform: capitalize;
        }
      }
    }
    .confidence-score {
      width: 100%;
      &-title {
        font-size: 10px;
        font-weight: 600;
        color: #98a2b3;
        &-highlight {
          color: #344054;
        }
      }

      .bars-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 6px;
        --bg-gray: #eaecf0;
        --color-orange: #475467;
        --color-red: #475467;
        --color-green: #475467;
        span {
          height: 4px;
          border-radius: 1px !important;
          width: 32%;
          background: var(--bg-gray);
          :hover {
            border-radius: 1px !important;
          }
        }
        // red
        span.red:nth-child(1) {
          background: var(--color-red);
        }
        span.red:nth-child(2) {
          background: var(--bg-gray);
        }
        span.red:nth-child(3) {
          background: var(--bg-gray);
        }

        // orange
        span.orange:nth-child(1) {
          background: var(--color-orange);
        }
        span.orange:nth-child(2) {
          background: var(--color-orange);
        }
        span.orange:nth-child(3) {
          background: var(--bg-gray);
        }

        // green
        span.green:nth-child(1) {
          background: var(--color-green);
        }
        span.green:nth-child(2) {
          background: var(--color-green);
        }
        span.green:nth-child(3) {
          background: var(--color-green);
        }
      }
    }
  }
}
.label-name > div {
  width: 240px;
  height: 23px !important;
  padding: 0px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px !important;
  justify: space-between;
  color: #344054 !important;
  :hover {
    color: #344054 !important;
    background: none !important;
  }
}
.label-name:hover {
  background: none !important;
}
.divider {
  margin: 0px;
}

.confidence-trigger {
  background: #6358ec;
  color: white;
  padding: 5px 10px;
  border-radius: 2px;
}
.confidence-btn-trigger {
  padding: 0;
  border: none;
  cursor: pointer;
  margin-left: 5px;

  &-space {
    color: #101828;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
  }
  &-number {
    font-weight: 500;
    display: block;
    width: 24px;
    background-color: #eaecf0;
    border-radius: 16px;
    color: #101828;
  }
}
.confidence-score-list {
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  border-radius: 8px !important;
  overflow: hidden;
  padding: 0px !important;
  &-item {
    font-size: 14px;
    font-weight: 500;

    &-fl-span {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .ant-checkbox-checked::after :focus {
    border: none;
  }
}
.confidence-score-list-fl-minus {
  width: 16px;
  height: 16px;
  border: 1px solid #475467;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: white;
  &-m {
    color: #475467;
    font-weight: bold;
  }
}
.confidence-score-checkbox {
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: hsl(210, 20%, 98%);
      border-color: #d0d5dd;
      border-radius: 4px;
      &:hover {
        border-color: #d0d5dd !important;
      }
    }

    .ant-checkbox-inner:after {
      border-color: #d0d5dd !important;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: hsl(210, 20%, 98%);
      border-color: #475467;
      border-radius: 4px;
      &:hover {
        border-color: #475467 !important;
      }
    }

    .ant-checkbox-inner:after {
      border-color: #475467 !important;
    }
  }
}
.confidence-score-checkbox .ant-checkbox-checked::after {
  border: 0px solid #475467 !important;
  overflow: hidden;
}

.accept-or-reject-parent {
  .ant-menu-submenu-title {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
  .accept-or-reject {
    width: fit-content;
    ul {
      display: flex;
      .ant-menu-item {
        margin-bottom: 4px;
      }
    }
    & + .ant-menu-submenu-arrow {
      display: none;
      height: 0;
      width: 0;
    }
  }
}
.accept-title {
  font-weight: 500;
  font-size: 14px;
  color: #344054;
}

.ant-tooltip-inner {
  .header-text {
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
  }
}
.subtype-dot {
  position: relative;
  padding-right: 12px;
  text-transform: capitalize;
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 5px;
    --box: 2px;
    width: var(--box);
    height: var(--box);
    border-radius: 100%;
    background-color: gray;
  }
}
.tag-radius {
  border-radius: 5px !important;
  &-color {
    color: white !important;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.1px;
    text-align: left;
    &-black {
      color: black;
    }
  }
}
.btn-accept-reject {
  background-color: #0000000d !important;
  border: none !important;
  border-radius: 5px !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #5072cb !important;
  border-color: #5072cb !important;
  width: 10px !important;
  height: 10px !important;
}

.ant-checkbox-inner {
  width: 16px !important;
  height: 16px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #475467 !important;
  border-radius: 4px !important;
}

.divider {
  margin-block: 1rem !important;
}
.main-header-AnalysisPanel {
  border: none;
  box-shadow: none !important ;
  border-radius: 0px !important;
}
.annoation-mainHead-box {
  border: 0px solid #eaecf0 !important;
  border-width: 0px 1px 1px 1px !important;
  border-radius: 0px !important;
}
.ant-popover-inner {
  border-radius: 8px !important;
  overflow: hidden !important;
  &-content {
    width: 240px;
    padding: 0px !important;
  }
}
.annotation-popup {
  border-radius: 8px;
  overflow: hidden;
}
.confidence-score-wrapper {
  .ant-menu-submenu-title {
    .confidence-score-wrapper-label {
      color: #344054;

      font-size: 14px;
      font-weight: 600 !important;
      line-height: 20px;
      text-align: left;
      &-tid:hover {
        text-decoration: underline !important;
      }
    }
  }
}
.list-item-color {
  color: #344054 !important;
  font-size: 14px;
  font-weight: 500;
  text-align: left;

  :hover {
    color: #344054 !important;
  }
  i:hover {
    color: black !important;
  }
  .ant-menu-submenu-title:active {
    background-color: #f9fafb !important;
  }
  &-icon {
    position: absolute;
    right: 1rem;
  }
}
.ant-menu-submenu:hover {
  background-color: #f9fafb !important;
}
.confidence-score-wrapper:hover {
  background-color: white;
}
.icon {
  padding: 3px;
}
.ant-menu-submenu-arrow {
  color: black !important;
}
.threat-actor-text-color {
  color: #ea3353;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.threat-actor-outlined-text-color {
  color: #ea3353;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.location-text-color {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.location-outlined-text-color {
  color: #03518b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.identity-text-color {
  color: #929697;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.identity-outlined-text-color {
  color: #929697;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.country-text-color {
  color: #929697;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.country-outlined-text-color {
  color: #929697;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.malware-text-color {
  color: #d89cc6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.malware-outlined-text-color {
  color: #d89cc6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.indicator-text-color {
  color: #ef8c35;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.indicator-outlined-text-color {
  color: #ef8c35;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.file-text-color {
  color: #ef8c35;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.tool-text-color {
  color: #5358a6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.tool-outlined-text-color {
  color: #5358a6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.attack-pattern-text-color {
  color: #377cb7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.attack-pattern-outlined-text-color {
  color: #377cb7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.sentence-entity-text-color {
  color: #377cb7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.sentence-entity-outlined-text-color {
  color: #377cb7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.vulnerability-text-color {
  color: #f7ca44;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.vulnerability-outlined-text-color {
  color: #f7ca44;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.campaign-text-color {
  color: #56bc8b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.campaign-outlined-text-color {
  color: #56bc8b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.course-of-action-text-color {
  color: #8cc63f;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.course-of-action-outlined-text-color {
  color: #8cc63f;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.infrastructure-text-color {
  color: #a1d7be;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.infrastructure-outlined-text-color {
  color: #a1d7be;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.intrusion-set-text-color {
  color: #52b5c1;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.intrusion-set-outlined-text-color {
  color: #52b5c1;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.entity-text-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}
.divider-margin-0 {
  margin-block: 0px !important;
}
.annotate-menu-list {
  width: 240px;
  height: 300px; /* Ensure sufficient height for scrollbar */
  overflow-y: auto; /* Enable vertical scrollbar if content overflows */

  /* Firefox-specific styling */
  scrollbar-color: #eaecf0 #f0f0f0; /* Thumb color, Track color */
  scrollbar-width: 8px; /* Firefox: thin scrollbar */
}

.annotate-menu-list:hover {
  border-radius: 0px !important;
}

/* WebKit scrollbar styling (Chrome, Safari, Edge) */
.annotate-menu-list::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.annotate-menu-list::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

.annotate-menu-list::-webkit-scrollbar-thumb {
  background-color: #eaecf0; /* Scroll thumb color */
  border-radius: 4px; /* Rounded corners */
  border: 1px solid #eaecf0; /* Optional border for better visibility */
}
.annotate-menu-list-sublist > .ant-menu {
  max-height: 300px !important;
  overflow-y: auto !important;
  border-radius: 8px !important;
  overflow: hidden !important;

  scrollbar-color: #eaecf0 #f0f0f0 !important;
  scrollbar-width: 8px !important;
}

.annotate-menu-list-sublist::-webkit-scrollbar {
  width: 8px !important;
}
.annotate-menu-list-sublist::-webkit-scrollbar-track {
  background: #f0f0f0 !important;
}

.annotate-menu-list-sublist::-webkit-scrollbar-thumb {
  background-color: #eaecf0 !important;
  border-radius: 4px !important;
  border: 1px solid #eaecf0 !important;
}
.annotation-popup-infoMessages > .ant-menu-submenu-title {
  margin: 0px !important;
}
.annotation-popup-color {
  color: #98a2b3 !important;
  > .annotation-popup-accept {
    color: #344054 !important;
  }
}
.annotation-popup-accept {
  color: #344054 !important;
}
.list-item-color-hover:hover {
  background: #f9fafb !important;
}
.list-item-color-hover {
  width: 240px !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 4px 16px !important;
  line-height: 15px !important;
  margin-bottom: 0px !important;
  overflow: auto !important;
}
.source-target {
  width: 120px !important;
  height: 40px !important;
  padding: 10px 16px 10px 16px !important;
  display: flex !important;
  justify-content: space-between !important;
  color: #344054 !important;
  margin: 0px !important;
}
.source-target:hover {
  background: #f9fafb !important;
  width: 100% !important;
  height: 40px !important;
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  left: auto !important;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 16px !important;
}
.list-pl {
  padding-right: 16px !important;
}

#executive-summary {
  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding-left: 0 !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
}
