.list-virtualizer {
  width: 100%;
  overflow: scroll;
  transition: background-color 0.3s;

  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover {
    scrollbar-color: var(--thumb-color) var(--track-color);
    &::-webkit-scrollbar-thumb {
      background-color: var(--thumb-color);
    }
  }
}
