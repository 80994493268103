@mixin grid-context {
  display: grid;
  gap: 15px;
  width: 100%;
  height: 100%;
}

.grid-1-col-1-row {
  @include grid-context;
  grid-template-columns: auto auto;
}

.grid-2-col-1-row {
  @include grid-context;
  grid-template-columns: auto auto;
}

.grid-2-col-2-row {
  @include grid-context;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid-3-col-2-row {
  @include grid-context;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid-3-col-3-row {
  @include grid-context;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.grid-graph-item {
  background-color: aquamarine;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex-auto {
  flex: 1 1 auto;
}
.flex-1 {
  flex: 1;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-5 {
  gap: 5px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}
