.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-dimension {
  width: 100%;
  height: 100%;
}

.full-screen-height {
  height: 100vh;
}

.ant-tooltip-content {
  font-size: 12px;
}

.ant-scroll-number-only p {
  color: #ffffff;
  font-size: 12px;
}

.spinner-loader {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-popover {
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 12px;
      margin: 0;
      padding: 0;
    }

    span {
      cursor: pointer;
    }
  }
}

button.btn-accept,
button.btn-reject {
  color: #ffffff;
  font-size: 12px;
  padding: 0 3px;

  &:hover,
  &:active,
  &:focus {
    border: none;
  }
}

button.btn-accept {
  background-color: $BUTTON_GREEN;
  margin-right: 2px;

  &.outline {
    background-color: #ffffff;
    color: $BUTTON_GREEN;
    border: 1px solid $BUTTON_GREEN;
    font-weight: bold;
  }
}

button.btn-reject {
  background-color: $BUTTON_RED;

  &.outline {
    background-color: #ffffff;
    color: $BUTTON_RED;
    border: 1px solid $BUTTON_RED;
  }
}

.btn-padding-0 {
  padding: 0;
}

.ant-menu {
  li.menu-item-group-sm {
    div.ant-menu-item-group-title {
      font-size: 8;
    }
  }

  .ant-menu-vertical {
    li.menu-item-sm {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 25px;
      height: 25px;
    }

    li.ant-menu-item-selected {
      color: inherit;
      background-color: inherit;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-alias {
  cursor: alias;
}

.error-icon {
  display: inline-block;
  height: 100px;
  font-size: 48px;
  color: #ffffff;
  background-color: #faad15;
  width: 100px;
  padding: 10px;
  border-radius: 50%;

  &.error-401 {
    background-color: #ff613b;
  }

  &.error-403 {
    background-color: inherit;
    color: #a26ef4;
    font-size: 96px;
    padding: 0;
  }

  &.error-500 {
    background-color: inherit;
    color: #ff613b;
    font-size: 96px;
    padding: 0;
  }
}

.app-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: $APP_FOREGROUND;
  box-shadow: 0 1px 3px #ccc;
  background-clip: border-box;
  overflow: auto;

  &-header {
    width: 100%;
    min-height: 50px;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
  }

  &-body {
    padding: 1.5rem;
    flex: 1 1 auto;
  }
}

.section-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &-header,
  &-header-bg {
    width: 100%;
    padding: 5px;
    margin: 0;
    border-radius: 4px;
  }

  &-header-bg {
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin-bottom: 2px;
  }

  &-body {
    padding: 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
}

.section-card-body {
  &-btns {
    display: flex;
    gap: 1rem;

    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #98a2b3;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.highlight {
  color: #101828;
}

.ant-input,
.ant-textarea,
.ant-input:disabled,
.ant-textarea:disabled {
  transition: box-shadow 0.15s ease;
  border: 0;
  color: #666;
  box-shadow: 0 0 3px rgba(50, 50, 93, 0.2), 0 1px 0 rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-family: inter, sans-serif;

  &:focus {
    box-shadow: 0 0 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
}

.ant-input {
  height: 38px;
}

.app-input {
  background-color: #fcfcfc;

  &:disabled {
    background-color: #fefefe;
  }

  input {
    background-color: #fcfcfc;

    &:disabled {
      background-color: #fefefe;
    }
  }
}

.ant-input-group-addon {
  border: 0;
  box-shadow: 0 0 3px rgba(50, 50, 93, 0.2), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.ant-form label,
.form-label {
  font-family: inter, sans-serif;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  width: 100%;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  display: none;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  display: none;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-menu.ant-menu-submenu-popup {
  .ant-menu.ant-menu-sub {
    max-height: 300px;
    overflow-y: auto !important;
    border-radius: 8px;
  }
}

.f-green {
  color: $BUTTON_GREEN;
}

.f-green-d {
  color: $TOOL_GREEN;
}

.b-green {
  background-color: $BUTTON_GREEN;
}

.f-red {
  color: $BUTTON_RED;
}

.b-red {
  background-color: $BUTTON_RED;
}

.f-black {
  color: $APP_BLACK !important;
}

.f-gray {
  color: #666;
}

.f-gray-disable {
  color: $GREY_DISABLE;
}

.f-gray-400 {
  color: #98a2b3 !important;
}

.f-gray-500 {
  color: #667085 !important;
}

.b-black {
  background-color: $APP_BLACK;
}

.f-white {
  color: #fff;
}

.b-white {
  background-color: #fff;
}

.b-inherit {
  background-color: inherit;
}

.b-app {
  background-color: $APP_BACKGROUND;
}

.bg-success {
  background-color: #ecfdf3;
}

.fg-success {
  color: #027a48;
}

.bg-warning {
  background-color: #fffaeb;
}

.fg-warning {
  color: #b54708;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectall {
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-nowrap {
  overflow: hidden;
  white-space: nowrap;
}

.menu-submenu {
  height: 40px !important;
  line-height: 40px !important;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.menu-submenu > .ant-menu-submenu-title {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  &:active {
    background-color: #f9fafb !important;
  }
}

.sub-menu-item {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  padding: 10px 16px !important;
  margin: 0px !important;
  width: 176px;
}

.sub-menu-item:hover {
  background-color: #f9fafb !important;
}

.menu-submenu:hover {
  background-color: #f9fafb !important;
}

.list-item {
  height: 25px !important;
  line-height: 25px !important;
  display: flex;
  align-items: center;
  padding: 0px !important;

  &-value-title-tooltip {
    border-radius: 8px !important;
    box-shadow: 0px 4px 6px -2px #10182808 !important;
    background: #101828 !important;
    overflow: hidden;
    box-shadow: 0px 12px 16px -4px #10182814 !important;
    width: 320px !important;
    max-width: 320px !important;
    top: 1932px;
    left: 426px;

    border-radius: 8px;

    padding: 12px;
  }
}

.list-item-value-title-tooltip .ant-tooltip-content .ant-tooltip-inner {
  background: #101828 !important;
  padding: 0px !important;
  min-height: auto !important;
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.list-item-value-title-tooltip .ant-tooltip-content .ant-tooltip-arrow {
  display: none;
}

.label-tooltip {
  padding: 8px 12px 8px 12px;
  gap: 0px;
  background-color: #101828 !important;
  border-radius: 8px;

  > .ant-tooltip-content .ant-tooltip-inner {
    background: #101828 !important;
    padding: 0px !important;
    min-height: auto !important;
  }
}

.label-tooltip .ant-tooltip-content .ant-tooltip-arrow {
  display: none;
}

.avatar {
  vertical-align: middle;
  color: black;
  font-weight: 500;
  background-color: #eaecf0;
  font-size: 16px;
}

.Options {
  text-align: center;
  border-radius: 8px !important;
  // padding: 10px 16px;
  width: 225px !important;

  &-Badge {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 10px 16px;

    > .ant-badge > .ant-avatar:hover {
      border-radius: 100% !important;
    }
  }

  &-name {
    text-align: start;
    margin-left: 1rem;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #344054;

    &-email {
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #667085;
    }

    &-divider {
      margin-block: 0px !important;
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.submenu-popclass {
  overflow: auto;
  max-height: 150px;
}

.relative-p,
.relative-postion,
.position-relative {
  position: relative;
}

.slide-in-view {
  background-color: $APP_BACKGROUND;
}

.processing {
  opacity: 0.5 !important;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.entity-tag {
  height: 20px;
  width: calc(100% - 35px);
  padding: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;

  .entity-type {
    height: 15px;
    background: #ffffff;
    padding: 2px 4px;
    margin-left: 4px;
    font-size: 10px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    white-space: nowrap;
    color: $APP_BLACK;
    font-weight: normal;
  }

  .entity-text {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

#bottom-relationship-anchor {
  position: absolute;
  left: 40%;
  bottom: 10%;
}

.site-page-header {
  margin-inline: -2rem;
  background-color: white;
  padding: 8px 45px 18px 47px !important;
  margin-bottom: 1rem;
  border: 1px solid #eaecf0;

  .ant-page-header-heading {
    align-items: center;

    &-tags {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 3px;
      }
    }

    &-extra {
      display: flex;
      align-items: center;

      & > * {
        margin-left: 3px;
        white-space: unset;
      }
    }
  }

  .title-btn {
    font-size: 14px;
    padding: 3px 5px;
    height: 20px;
    border: none;
    line-height: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .tlp-btn {
    min-width: 90px;
    box-shadow: none !important;
  }
}

.btn-viewsource {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: none;
  gap: 8px;
  font-weight: 500;
  padding: 1rem;
  box-shadow: none !important;

  &-icon {
    width: 16px;
    height: 16px;
    top: 1.38px;
    left: 1.37px;
    gap: 0px;
  }
}

.auto-scroll {
  overflow: auto;
}

.ant-select-tree-treenode-disabled .ant-select-tree-title {
  color: rgba(102, 102, 102, 0.8) !important;
}

.message-box-show {
  background-color: #000;
  color: #ffffff;
  opacity: 0.6;
  width: auto;
  padding: 15px;
  padding-top: 5px;

  .ant-notification-notice-message {
    color: #ffffff;
  }

  .ant-notification-close-x {
    display: none;
  }
}

.message-box-show-error {
  color: $HOVER_RED;
  background-color: #fef7f8;
  border: 1px solid $HOVER_RED;

  .ant-notification-notice-message {
    color: $HOVER_RED;
    font-weight: bold;
  }

  .ant-notification-close-x {
    color: $HOVER_RED;
  }
}

.message-box-action-btn {
  color: white;
  background-color: black;

  margin-right: 8px;

  &:hover {
    color: rgb(139, 139, 242);
    background-color: black;
  }
}

.app-modal-wrapper {
  .ant-modal-title {
    text-align: center;
  }
}

.updown-caret {
  .up-caret {
    width: 0;
    height: 0;
    border: solid 5px transparent;
    background: transparent;
    border-bottom: solid 6px #9a9a9a;
    border-top-width: 0;
    cursor: pointer;
  }

  .down-caret {
    width: 0;
    height: 0;
    border: solid 5px transparent;
    background: transparent;
    border-top: solid 6px #9a9a9a;
    border-bottom-width: 0;
    margin-top: 3px;
    cursor: pointer;
  }
}

.table-column-title-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bdr-2 {
  border-radius: 2px;
}

.bdr-5 {
  border-radius: 5px;
}

.bdr-8 {
  border-radius: 8px;
}

.bdr-10 {
  border-radius: 10px;
}

.delete-button-modal {
  width: 400px !important;
  margin-top: 100px;
}

.ant-modal-content {
  border-radius: 12px;
}

.DeleteDocument {
  &-modal {
    text-align: left;
  }

  &-featured-icon {
    margin-bottom: 20px;
  }

  &-heading-title {
    font-family: inter, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
  }

  &-paragraph {
    color: #667085;
    font-family: inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &-paragraph-span {
    display: block;
  }

  &-button-head {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 32px;
  }

  &-button-cancelText {
    color: #344054;
    font-size: 16px;
    font-weight: 600;
    font-family: inter, sans-serif;
    line-height: 24px;
    background-color: #ffffff;
    border: 1px solid #d0d5dd;
    padding: 10px 58px;
    border-radius: 8px;
    cursor: pointer;
  }

  &-button-okText {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    font-family: inter, sans-serif;
    line-height: 24px;
    background-color: #d92d20;
    border: 1px solid #d92d20;
    padding: 10px 58px;
    border-radius: 8px;
    cursor: pointer;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0px);
}

.divider {
  margin: 0px 0px !important;
}

.ant-list-split .ant-list-item {
  border-bottom: none !important;
}

.list-item:hover {
  color: #344054 !important;
  background: #f9fafb !important;
}

.list-item {
  height: 40px !important;
  margin: 0px !important;
  background-color: white !important;
  color: #344054 !important;
  padding: 10px 16px !important;
}

.user-options > .ant-menu {
  border-radius: 8px !important;
}

.left-sidebar-options-panel {
  position: relative;
  left: -24px;
  margin-right: -48px;

  &-mainHead {
    background-color: #f9fafb;
    padding: 20px 0px 0px 0px;
  }

  &-Header {
    display: inline-flex;
    margin: 0px 0px 16px 24px;
  }

  &-Heading {
    color: #000000;
    background-color: #f9fafb;
    padding: 0px;
    width: 100%;
    margin-bottom: 20px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 4px 12px 4px 0px;
  }

  &-body {
    padding: 24px;
  }
}

.left-sidebar-options-panel-section {
  padding-top: 0px !important;
}

.left-sidebar-options-panel-section .total-stat-widget {
  padding: 22px 0px 22px 16px !important;
}

.overview-section-card {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.rc-virtual-list-scrollbar-thumb {
  background: #eaecf0 !important;
}

.rulesButton {
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background-color: #ffffff;

  [ant-click-animating-without-extra-node="true"]::after {
    --antd-wave-shadow-color: none !important;
  }

  &-addRule {
    color: #101828 !important;
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    height: auto !important;
  }

  &-icon {
    margin-left: 8px;
  }
}

.performance-panel-dropdown-body {
  display: flex;
  gap: 8px;
}

.performance-panel-searchbar {
  width: 272px;
  box-shadow: none;
  font-family: inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #667085;
  background-color: #f2f4f7;
  border: 1px solid #f2f4f7 !important;
  border-radius: 5px !important;
  padding-left: 10px;

  ::placeholder {
    color: #667085;
  }

  :focus {
    border: none;
  }

  input.ant-input {
    box-shadow: none;
    background-color: #f2f4f7 !important;
  }

  &-searchoutlined {
    color: #98a2b3 !important;
    margin-right: 12px;
  }
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 16px 24px;

  &-content {
    display: flex;
    gap: 12px;

    color: #344054;

    &-btn {
      &-prev {
        display: flex;
        align-items: center;
        padding: 8px 14px 8px 14px;
        gap: 8px !important;
        border-radius: 8px !important;
        border: 1px solid #d0d5dd !important;
        width: 86px;
        height: 36px;
        box-shadow: 0px 1px 2px 0px #1018280d;
        background-color: #ffffff !important;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid #d0d5dd !important;
          color: #999fa9 !important;
        }
      }

      &-nxt {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 8px 14px 8px 14px;
        gap: 8px !important;
        border-radius: 8px !important;
        border: 1px solid #d0d5dd !important;
        width: 60px;
        box-shadow: 0px 1px 2px 0px #1018280d;
        color: #344054;
        background-color: #ffffff !important;

        width: 60px;
        box-shadow: 0px 1px 2px 0px #1018280d !important;
        color: #999fa9 !important;
        background-color: #fff !important;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid #d0d5dd;
          box-shadow: 0px 1px 2px 0px #1018280d;
          color: #999fa9 !important;
        }
      }
    }
  }
}

.left-sidebar-options-panel-mainBody .ant-table-thead > tr > th {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  padding: 0px;
  padding-bottom: 11px;
  cursor: pointer;
  background-color: #f9fafb;
}

.left-sidebar-options-panel-mainBody .ant-table-thead > tr > th::before {
  content: none !important;
}

.left-sidebar-options-panel-mainBody .ant-table-tbody > tr > td {
  min-width: 277px;
  padding: 16px 0px;
  border-bottom: 1px solid #eaecf0;
}

.left-sidebar-options-panel-mainBody
  .ant-table-tbody
  > tr
  > td.title-column-id {
  min-width: 140px;
  max-width: 140px;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.buckets-table-collection .ant-btn-link:hover {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

.buckets-table-collection .ant-table-tbody {
  background-color: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}

.ant-table-tbody .title-column-id {
  position: relative;
  left: 24px;
}

.ant-table-tbody .title-column-more-actions-icon {
  position: relative;
  right: 24px;
}

.buckets-table-collection .ant-table-content {
  min-height: 583px;
  background-color: #ffffff;
}

.filter-selector {
  border-radius: 20px !important;
}

.tag-style-value {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.tag-style-entity-type {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px 2px 3px;
  gap: 6px;
  border-radius: 16px;
  background-color: #f2f4f7;
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  border: none;
}

.tag-style-rule {
  padding: 2px 8px;
  border-radius: 16px;
  background-color: #fee4e2;
  color: #912018;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border: none;
  text-align: center;
}

.tag-style-rule.red {
  background-color: #d1fadf;
  color: #05603a;
}

.tag-style-common {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.tag-style-common.disabled {
  color: #667085;
}

.table-even-row {
  background-color: #ffffff;
}

.table-odd-row {
  background-color: #fcfcfd;
}

.menu-item-rules {
  color: #344054 !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 20px;
  padding: 10px 16px !important;
}

.menu-item-rules.delete {
  color: #f04438;
}

.buckets-documents-list {
  padding: 0px 8px;
  border-radius: 16px;
  background-color: #eaecf0;
  border: none;
  height: 22px;

  &:hover {
    background-color: #eaecf0 !important;
  }
}

.buckets-documents-list-item {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;

  &:hover {
    background-color: #eaecf0 !important;
  }
}

.buckets-description-list-item {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.suffix-icon {
  display: flex;
  align-items: center;
  height: 100%;

  &-all {
    background-color: #eaecf0;
    padding: 2px 8px;
    border-radius: 16px;
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.more-actions-icon:hover path {
  stroke: #667085;
}

.more-actions-button.ant-btn-text:hover {
  background: none !important;
  border-color: transparent;
}

.default-value-column {
  padding-right: 10px !important;
}

.filterbar-optionlist {
  width: 240px;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 4px 5px #00000008 !important;
  border-radius: 8px;

  &-header {
    .ant-list-header {
      padding: 0px !important;
    }

    &-options {
      padding: 10px 16px 10px 16px;
    }
  }
}

.confidence-score-checkbox > span {
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.dropdown-options.selected-option {
  cursor: pointer;
  padding: 10px 16px;
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: #ffffff;
  text-align: left;
}

.dropdown-options.selected-option:hover {
  background-color: #f9fafb !important;
}

.style-value-entity {
  overflow: hidden;
  max-width: 140px;
  text-overflow: ellipsis;
}
