$APP_BLUE: #3498db;
$APP_BLACK: #444444;
$APP_BACKGROUND: #f0f2f5;
$APP_FOREGROUND: #fafafa;
$HOVER_RED: #ff0000;
$BUTTON_GREEN: #71bd71;
$APP_GREEN: #71bd71;
$BUTTON_RED: #d3556f;
$BUTTON_BLUE: #3498db;
$TOOL_GREEN: #228b22bb;
$SENTENCE_HIGHLIGHT: #ddeffc;
$PAGE_LOADER: #ffbdc2;
$GREY_DISABLE: #00000040;
