* {
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: inter, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: inter, sans-serif, monospace;
}
:root {
  --track-color: #fcfcfc;
  --thumb-color: rgba(0, 0, 0, 0.3);
}
/* ===== Scrollbar CSS ===== */

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--thumb-color) var(--track-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  --scrollbar-thickness: 11px;
  width: var(--scrollbar-thickness);
  height: var(--scrollbar-thickness);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumb-color);
  border-radius: 10px;
  border: 3px solid var(--track-color);
}
*::-webkit-scrollbar-track {
  background-color: var(--track-color);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 40px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 0 0;
  font-size: 12px;
}
