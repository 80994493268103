#documents-search {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.documents-search {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-searchboxDivider {
    margin: 12px 0px !important;
    width: 827px;
    border-color: #eaecf0;
    position: relative;
    left: -12px;
  }

  &-filterHead {
    margin-bottom: 48px;
  }

  &-noResults {
    text-align: center;
    margin-top: 34px;

    &-title {
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      margin-bottom: 15px;
      color: #000000;
    }

    &-textMain {
      margin-bottom: 15px;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    &-text {
      margin-bottom: 0px;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    &-list {
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 0px;
    }
  }
}

.search-box-textfield {
  background: var(--Gray-100, #f2f4f7) !important;
  border-radius: 5px;
  border: 1px solid var(--Gray-200, #eaecf0);
  box-shadow: none;
}

.search-box-textfield .ant-input {
  box-shadow: none !important;
  background: var(--Gray-100, #f2f4f7);
  color: var(--Gray-500, #101828);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.ant-input-affix-wrapper.search-box-textfield:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #eaecf0 !important;
}

.search-box-textfield .ant-input-prefix {
  height: 24px;
  padding-top: 12px;
  margin-right: 8px;
}

.custom-placeholder ::placeholder {
  color: var(--Gray-500, #667085);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.search-applyButton {
  border-radius: 8px;
  border-color: #eaecf0 !important;
  background-color: white !important;

  color: var(--Gray-900, #101828) !important;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  box-shadow: none !important;
}

#search-bar {
  button {
    height: 38px;
  }
}

.search-results {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;

  &-doc-item {
    border: 1px solid #0000000d;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 5px;
    padding: 12px;
  }

  &-contentText {
    margin-bottom: 9px;
  }

  &-uploaded {
    color: #98a2b3;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: left;
    margin-right: 4px;
  }

  &-date {
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: left;
  }

  &-title {
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 368px;
  }

  &-paragraph {
    color: #667085;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.72px;
    text-align: left;
  }

  &-value {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: left;
    color: #101828;
  }

  &-valueText {
    color: #98a2b3;
  }
}

#search-results .doc-item:hover {
  border: solid 1px #1890ff;
}

@media screen and (min-width: 720px) {
  #search-results {
    grid-template-columns: repeat(2, 1fr);
  }
}

// @media screen and (min-width: 1200px) {
//   #search-results {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (min-width: 2000px) {
//   #search-results {
//     grid-template-columns: repeat(4, 1fr);
//   }
// }

// @media screen and (min-width: 3000px) {
//   #search-results {
//     grid-template-columns: repeat(5, 1fr);
//   }
// }

// @media screen and (min-width: 4000px) {
//   #search-results {
//     grid-template-columns: repeat(6, 1fr);
//   }
// }

.searchInput {
  width: 100%;

  &-input {
    border: none;

    box-shadow: none;
    outline: none;
    width: 208px;

    .ant-input {
      box-shadow: none;
    }
  }

  &-input:hover {
    box-shadow: none;
    border-color: #f2f4f7 !important;
  }
}

.hover-list-item {
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  height: 40px !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &-value {
    // font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2277b5 !important;
    :hover {
      text-decoration: underline;
    }

    &-title {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}

.hover-list-item:hover {
  background-color: #f9fafb !important;
  border-radius: 0px !important;
}

.list {
  max-height: 200px;
  // overflow-y: auto;
}
