@import "colors";

.grid-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: grid-loader 1.2s linear infinite;

    &:nth-child(1) {
      top: 8px;
      left: 8px;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: 8px;
      left: 32px;
      animation-delay: -0.4s;
    }

    &:nth-child(3) {
      top: 8px;
      left: 56px;
      animation-delay: -0.8s;
    }

    &:nth-child(4) {
      top: 32px;
      left: 8px;
      animation-delay: -0.4s;
    }

    &:nth-child(5) {
      top: 32px;
      left: 32px;
      animation-delay: -0.8s;
    }

    &:nth-child(6) {
      top: 32px;
      left: 56px;
      animation-delay: -1.2s;
    }

    &:nth-child(7) {
      top: 56px;
      left: 8px;
      animation-delay: -0.8s;
    }

    &:nth-child(8) {
      top: 56px;
      left: 32px;
      animation-delay: -1.2s;
    }

    &:nth-child(9) {
      top: 56px;
      left: 56px;
      animation-delay: -1.6s;
    }
  }
}

@keyframes grid-loader {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.spinner-loader {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    transform-origin: 40px 40px;
    animation: spinner-loader 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: #fff;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }

    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }

    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }

    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }

    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }

    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }

    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }

    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }

    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }

    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }

    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}

@keyframes spinner-loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.skeleton-loader {
  position: relative;
  background-color: #eee !important;
  cursor: progress;

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(#fefefe),
      to(transparent)
    );

    background: linear-gradient(90deg, transparent, #fefefe, transparent);

    animation: skeleton-loader 2s infinite;
  }

  @keyframes skeleton-loader {
    100% {
      transform: translateX(100%);
    }
  }
}

.continuous-loader {
  width: 100%;
  height: 20px;
  // background: linear-gradient(90deg, #0001 33%, #0005 50%, #0001 66%) #f2f2f2;
  background: linear-gradient(90deg, #f2f2f2 33%, $PAGE_LOADER 50%, #f2f2f2 66%)
    #f2f2f2;
  background-size: 300% 100%;
  animation: continuous-loader 2s infinite linear;
}

@keyframes continuous-loader {
  0% {
    background-position: right;
  }
}

.spin-loader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;

  div {
    box-sizing: content-box;
  }

  div > div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $PAGE_LOADER;
    // background: #ff727d;
    animation: spin-loader 1s linear infinite;
  }

  div:nth-child(1) > div {
    left: 74px;
    top: 44px;
    animation-delay: -0.875s;
  }

  > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 80px 50px;
  }

  div:nth-child(2) > div {
    left: 65px;
    top: 65px;
    animation-delay: -0.75s;
  }

  > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 71px 71px;
  }

  div:nth-child(3) > div {
    left: 44px;
    top: 74px;
    animation-delay: -0.625s;
  }

  > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 50px 80px;
  }

  div:nth-child(4) > div {
    left: 23px;
    top: 65px;
    animation-delay: -0.5s;
  }

  > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 29px 71px;
  }

  div:nth-child(5) > div {
    left: 14px;
    top: 44px;
    animation-delay: -0.375s;
  }

  > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 20px 50px;
  }

  div:nth-child(6) > div {
    left: 23px;
    top: 23px;
    animation-delay: -0.25s;
  }

  > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 29px 29px;
  }

  div:nth-child(7) > div {
    left: 44px;
    top: 14px;
    animation-delay: -0.125s;
  }

  > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 50px 20px;
  }

  div:nth-child(8) > div {
    left: 65px;
    top: 23px;
    animation-delay: 0s;
  }

  > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 71px 29px;
  }

  @keyframes spin-loader {
    0% {
      opacity: 1;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.5, 1.5);
    }
    100% {
      opacity: 0;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1, 1);
    }
  }
}
