.metadata-sidebar {
  display: flex;
  gap: 24px;

  &-head {
    display: flex;
    background-color: #f9fafb !important;
    height: 20px;
    gap: 24px;
  }

  &-options {
    display: flex !important;
    margin: 0px !important;
    padding: 0px !important;

    background-color: #f9fafb !important;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px !important;
    text-align: left;

    &-profiles {
      display: flex !important;
      margin: 0px !important;
      padding: 0px !important;
      background-color: #f9fafb !important;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px !important;
      text-align: left;
    }

    &-value {
      margin-left: 8px;
    }
  }
}
