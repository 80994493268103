@import "colors";
@import "variables";
@import "utils";
@import "spaces";
@import "annotations";
@import "mixins";
@import "layout";
@import "antd-custom";

.ant-btn-primary {
  background-color: $APP_BLUE;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $APP_BLACK;
}

.stix-viewer-block .sidebar {
  z-index: 999;
}

.app {
  &-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f9fafb;
    color: $APP_BLACK;
    overflow: hidden;
    position: relative;
  }

  &-header {
    height: 64px;
    width: 100%;
    padding: 10px 24px 5px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 1px solid #eaecf0;
    position: relative;
    line-height: normal;
    z-index: 9;

    .header-user-info {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }

    .header-user-avatar {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      justify-content: space-between;
      padding-left: 1rem;
    }

    .header-loader {
      position: absolute;
      left: 0;
      bottom: -5px;
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
  }

  &-body {
    overflow: auto;
    flex: 1;
  }

  &-content {
    flex: 1;
    height: 100%;
    width: 100%;
    padding: 0px 24px;
    overflow-x: auto;
    @include hide-scroll-bar;
  }

  &-footer {
    position: relative;
    height: $FOOTER_HEIGHT;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7a7a7a;
    background-color: #fafafa;
    border: 1px solid #eaeaea;
    margin-top: 5px;
    padding: 5px;
    overflow: hidden;
    z-index: 9999;

    &-text {
      font-size: 14px;
      text-align: center;
      margin: 0;
    }

    &-link {
      color: #7a7a7a;
      text-decoration: none;

      &:hover {
        color: $HOVER_RED;
      }
    }
  }
}
.width {
  width: 10rem;

  display: flex;
  justify-content: flex-end;
}
.pane-title {
  color: #000000;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  font-family: inter, sans-serif;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #d0d5dd;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #101828;
}

.ant-tabs-tab-active:hover .ant-tabs-tab-btn {
  color: #101828 !important;
}

.ant-tabs-ink-bar {
  display: none;
}

.import {
  &-header {
    display: flex;
  }

  &-button-showdata {
    display: flex;
    background-color: #f2f4f7;
    border-radius: 16px;
    padding: 4px 14px 4px 14px;
    border-color: #eaecf0 !important;
    align-items: flex-start;
  }

  &-button-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: -10px;
  }

  &-icon-tick {
    margin-right: 10px;
  }
  &-span-issue {
    background-color: white;
    padding: 0px 10px 2px 8px;
    border-radius: 16px;
    margin-left: 10px;
    margin-top: 10px;
  }

  &-button {
    display: flex;
    border: 1px solid #eaecf0 !important;
    border-radius: 8px;
    margin-right: 10px;
    &:hover {
      background-color: #f2f4f7;
      border-color: #eaecf0;
    }
  }

  &-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &-document-icon {
    margin-left: 8px;
    height: 20px;
    width: 20px;

    &:hover {
      color: #101828;
    }
  }
}
.ant-btn:hover,
.ant-btn:focus {
  color: #101828 !important;
}

.ant-modal-content {
  width: 400px;
}
.modal-main-top {
  width: 400px !important;
  top: 32px !important;
}

.file-icon {
  color: #98a2b3;
  width: 16px;
  height: 16px;
}
.documents-queue {
  overflow: auto;
  width: 300px;
  max-height: 250px;
  &-body {
    overflow: auto;

    .queued-document {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $APP_BLUE;
      width: 100%;
      height: 30px;
      padding: 3px 10px;
      font-size: 18px;
      border-radius: 3px;
      margin-bottom: 10px;
      overflow: hidden;

      &-title {
        display: flex;
        align-items: center;
        max-width: 70%;
        &-text {
          color: #101828 !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          color: $APP_BLACK;
          margin-left: 0.5rem;
          margin-right: 3px;

          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
        }
      }
      &-import {
        display: flex;
        align-items: center;
        color: #98a2b3;

        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        .iconload {
          margin-left: 0.5rem;
          width: 16px;
          height: 16px;
          top: 1.33px;
          left: 1.33px;
          gap: 0px;
          border: 1.67px 0px 0px 0px;
          opacity: 0px;
        }
      }
    }
    .failed-item {
      border: 1px dashed $BUTTON_RED;
    }
  }
}
.cross {
  margin-left: 0.5rem;
  width: 13px;
  height: 13px;
  top: 1.33px;
  left: 1.33px;
  gap: 0px;
  border: 1.67px 0px 0px 0px;
}
.queued-document-fail {
  display: flex;
  align-items: center;
  color: #98a2b3;

  font-size: 12px;
  font-weight: 500;
}

.ant-menu-dark {
  .ant-menu-item {
    color: white;

    &:hover {
      background-color: #1e2024 !important;
      color: white !important;
    }

    &-selected {
      background-color: #1e2024 !important;
      color: white !important;

      .sidebar-icon path {
        stroke: #f04438;
      }
    }
  }
}

.sidebar-icon {
  width: 20px;
  height: 20px;
  stroke: white;
}

.custom-popover {
  .ant-popover-inner {
    border-radius: 8px;
    width: 225px;
  }
  .ant-popover-arrow {
    display: none;
  }
}
.app-popover .ant-popover-arrow {
  display: none;
}

.button {
  border: none;
}
.outerHead-box-queuePane {
  border-radius: 8px;
  padding: 0px 0px !important;
  :hover {
    background-color: white;
    border-radius: 8px;
  }
}
.outer-box-queuePane {
  padding: 0px 0px !important;
}
.ant-menu-item:active {
  background: none;
}
.ant-menu-item-selected {
  background-color: unset !important;
}
