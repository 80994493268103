.radio-style {
  height: 30px !important;
  line-height: 15px !important;
  margin-bottom: 10px !important;
  color: #475467;
}
.modal-radio-title {
  font-family: inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.radio-style .ant-radio-inner {
  border-color: #475467 !important;
}

.radio-style .ant-radio:checked .ant-radio-inner {
  background-color: #475467;
  border-color: #475467;
}

.radio-style:hover .ant-radio-inner {
  border-color: #475467;
}

.ant-radio-inner::after {
  background-color: #475467 !important;
}
