.ant-btn.ant-btn-primary {
  color: #fff;
  background-color: #7f56d9;
  border-color: #7f56d9;

  &:focus {
    color: #fff !important;
    border-color: #777 !important;
  }
}
