@import "colors";
@import "variables";

.action-tooltip {
  .ant-tooltip-inner {
    color: $APP_BLACK !important;
  }
}

.display-tooltip {
  .ant-tooltip-inner {
    color: $APP_BLACK !important;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.total-stat-widget {
  width: 100%;
  min-height: 100px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px #ccc;
  padding: 15px;
  overflow: hidden;

  .title {
    grid-column: 1 / 5;
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  &-count {
    display: flex;
  }
  .icon {
    grid-column: 5 / 6;
    grid-row: 1 / 3;
    font-size: 1.3rem;
  }

  .count {
    font-family: Inter, sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #101828;
  }

  .alttext {
    color: #101828;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .compare {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .compare-text {
    display: inline-block;
    color: #027a48;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-right: 4px;
  }

  .dynamic-width {
    display: inline-flex;
    width: auto;
    align-items: center;
  }

  .custom {
    background-color: #ecfdf3;
    color: #027a48;
    padding: 4px 10px 4px 12px;
    border-radius: 16px;
  }
}

#grouped-relationships {
  .retionship-list-item {
    font-size: 12px;
  }

  .ant-list-item {
    width: 100%;
    padding: 5px 0;
  }

  .ant-list-item .ant-collapse {
    font-size: 12px;
    width: 100%;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 15px !important;
    padding-right: 0px !important;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin-left: -20px;
  }
}

.report-card {
  padding: 12px;
}

.titleWithLegend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}

.overview-section-card {
  font-weight: bold;
  margin-right: 20px;
}

.custom-legend {
  display: flex;
  gap: 20px;
  padding-right: 10px;
}

.legend-item {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.legend-text {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.apex-charts {
  margin-left: -18px;
  margin-top: -18px;
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  fill: #667085 !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  box-shadow: 0px 12px 16px -4px #10182814 !important;
  border: none !important;
}

.custom-tooltip {
  background-color: #ffffff;
  color: #344054;
  padding: 12px;
  border-radius: 8px;
  font-family: Inter, sans-serif !important;
  font-size: 12px;
  text-align: center;
  min-width: 100px;
}

.tooltip-month {
  color: #344054;
  display: block;
  margin-bottom: 4px;

  font-family: Inter, sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
}

.tooltip-value {
  font-family: Inter, sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #667085;
}

.date-picker-button .ant-picker {
  border: none;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.date-picker-button .ant-picker-input > input {
  text-align: center;
  color: #101828;
  font-size: 16px;
  font-weight: 500;
}

.custom-date-picker-dropdown .ant-picker-ranges .ant-picker-range {
  color: #101828;
}

.ant-picker-range .ant-picker-active-bar {
  display: none;
}

.date-picker-button .ant-picker-suffix {
  display: flex;
  align-items: center;
}

.date-picker-button .ant-picker-suffix img {
  width: 12px;
  height: 12px;
}

.date-picker-button:hover {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
