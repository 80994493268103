#extraction-performance-dashboard,
#report-performance-overview {
  .total-stats-section {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;

    & > div {
      margin-right: 16px;
      padding: 17px 0px 19px 16px;
      border: 1px solid #eaecf0;
      border-radius: 5px;
      box-shadow: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .ant-collapse-header .justify-start .full-width {
    margin-right: 12px;
  }
  .ant-collapse-content-box .justify-start {
    margin-right: 42px;
  }

  .status-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: -5px;
  }

  .status-info-label-counthead {
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
  }

  .status-info-arrowHead {
    margin-top: 13px;
    display: flex;
    align-items: center;
  }

  .status-info-arrow {
    padding-left: 16px;
    display: flex;
    align-items: center;
  }

  .status-info-label-count {
    font-family: Inter, sans-serif;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: #98a2b3;
  }

  section {
    padding-top: 16px;
  }

  .performance-report-card {
    .ant-card {
      min-height: 268px;
    }

    .ant-collapse-content > .ant-collapse-content-box,
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.report-annotation .performance-report-card .ant-card {
  background-color: #ffffff;
}
.report-annotation .performance-report-card .report-card.white {
  background-color: #f9fafb;
}

#report-performance-overview {
  overflow-x: hidden;
  overflow-y: auto;

  .empty-report-info {
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    height: 100%;
    padding: 20px 30px;
    margin-top: 27px;
  }

  .empty-report-button {
    width: 153px;
    height: 40px;
    background-color: #ffffff;
    padding: 10px 16px 10px 16px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
  }

  .empty-report-button-text {
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
}

.report-confidence {
  padding-right: 8px !important;
}

.report-annotation {
  padding-left: 8px !important;
}

.report-card {
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 17px 16px 0px 16px;
}

.report-card .ant-card-head {
  border-bottom: none;
  padding: 0px;
  margin-bottom: 20px;
  min-height: 0px;
}

.report-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 0px !important;
}

.report-card .ant-card-body {
  padding: 0px;
}

.collapseHeader .ant-collapse-header {
  padding: 0px 0px 33px 0px !important;
}
.collapseHeader .ant-collapse-content-active .ant-collapse-content-box {
  padding-top: 0px !important;
}
.collapseHeader
  .ant-collapse-content-active
  .ant-collapse-content-box
  .justify-start {
  margin-bottom: 18px;
}

.status-info-label {
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.extraction-overview {
  border: 0px solid #eaecf0 !important;
  border-width: 0px 1px 1px 1px !important;
  border-radius: 0px !important;
  padding: 17px 16px 0px 16px !important;

  &-title {
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin: 7px 0px 8px 0px;
  }

  &-text {
    color: #101828;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    &-bold {
      font-weight: 700;
    }
  }
}
