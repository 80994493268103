.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  height: 40px;
  padding: 6px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-self: center;
    margin-right: 8px;
  }

  div {
    width: 50px;
    white-space: nowrap;
    display: flex;
    justify-content: end;
    span {
      cursor: pointer;
    }
  }
}
.file-item-edit {
  margin-top: 8px;
}
.file-upload-edit {
  .ant-btn-circle.ant-btn-sm {
    top: 4px;
  }
  .f-green-d.ant-btn-circle.ant-btn-sm {
    position: relative;
    top: 3px;
    &:focus .anticon-check {
      position: static;
    }
    .anticon-check {
      top: 5px;
      left: 4.5px;
    }
  }
  .f-red.ant-btn-circle.ant-btn-sm {
    position: relative;
    top: -1px;
    min-width: 25px;
    height: 25px;
    &:focus .anticon-close {
      position: absolute;
    }
    .anticon-close {
      top: 5px;
      left: 4.5px;
    }
  }
}
