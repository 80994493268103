/* Class utils for margins */
.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* Class utils for paddings */

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.bd-0 {
  border: 0 !important;
}

.bd-0-top {
  border-top: 0 !important;
}

.bd-0-bottom {
  border-bottom: 0 !important;
}

.bd-0-left {
  border-left: 0 !important;
}

.bd-0-right {
  border-right: 0 !important;
}

.bd-0-y {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.bd-0-x {
  border-left: 0 !important;
  border-right: 0 !important;
}

.w-100p,
.full-width {
  width: 100%;
}

.h-100p,
.full-height {
  height: 100%;
}

.lh-20 {
  line-height: 20px !important;
}
