@import "mixins";

#organisation {
  .profiles {
    padding-left: 24px;

    &-head {
      display: flex;
      margin-top: 1px;
      margin-bottom: 22px;
      align-items: center;

      &-infos {
        color: #101828;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        margin-left: 8px;
      }
    }

    &-holder {
      &-switch {
        display: flex;
        gap: 16px;
        margin-bottom: 24px;

        &-button {
          color: #101828;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;

          &-toggle {
            margin-left: 8px;
          }
        }
      }

      &-form {
        &-label {
          margin-bottom: 16px !important;

          .ant-form-item-label {
            padding: 0px 0px 6px 0px !important;
          }

          .ant-form-item-label > label {
            color: #344054;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
          }

          &-input {
            width: 408px !important;
            height: 44px !important;
            padding: 10px 14px 10px 14px !important;
            border-radius: 8px !important;
            border: 1px solid #d0d5dd !important;
            background-color: #ffffff !important;
            color: #101828 !important;
            font-family: Inter, sans-serif !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            text-align: left;
            box-shadow: 0px 1px 2px 0px #1018280d !important;

            .ant-input {
              background-color: #ffffff !important;
              box-shadow: none !important;
              height: 24px;
              color: #101828;
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              padding: 0px;
            }

            .ant-input-group-addon {
              background-color: #ffffff !important;
              box-shadow: none !important;
              padding: 0px !important;
            }
          }

          &-button {
            width: auto;
            height: 40px !important;
            padding: 10px 16px 10px 16px !important;
            border-radius: 8px !important;
            border: 1px solid #eaecf0 !important;
            background-color: #ffffff !important;

            color: #101828 !important;
            font-family: Inter, sans-serif !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 20px !important;
            text-align: left;
            margin-top: 8px !important;
          }
        }
      }
    }
  }

  .profiles-holder-form-label-inputapi .ant-input-group .ant-input-group-addon {
    padding: 0px;
    color: white;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: none !important;
    box-shadow: none !important;
  }

  .mask-button {
    background-color: white;
    display: flex !important;
    align-items: center;
    padding: 0px 8px 0px 0px !important;
    height: 20px !important;

    &:hover,
    &:focus {
      background: #ffffff !important;
    }
  }

  .copy-button {
    background-color: white;
    display: flex !important;
    align-items: center;
    padding: 0px 0px 0px 8px !important;
    height: 20px !important;

    &:hover,
    &:focus {
      background: #ffffff !important;
    }
  }
}
