.annotation {
  position: relative;
  cursor: pointer;
  &:hover {
    span.remove-annotation {
      display: inline;
    }
  }
}

#text-selection-marker,
.text-selection-marker {
  font-weight: bold;
  background-color: #f2f3cf;
  padding: 0;
}

.threat-actor {
  background-color: #ea3353;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.threat-actor-outlined {
  border: 1px solid #ea3353;
  color: #ea3353 !important;
  padding: 0 3px;
  font-weight: bold;
}
.location {
  background-color: #03518b;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.location-outlined {
  border: 1px solid #03518b;
  color: #03518b !important;
  padding: 0 3px;
  font-weight: bold;
}
.identity {
  background-color: #929697;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.identity-outlined {
  border: 1px solid #929697;
  color: #929697 !important;
  padding: 0 3px;
  font-weight: bold;
}
.country {
  background-color: #929697;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.country-outlined {
  border: 1px solid #929697;
  color: #929697 !important;
  padding: 0 3px;
  font-weight: bold;
}
.malware {
  background-color: #d89cc6;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.malware-outlined {
  border: 1px solid #d89cc6;
  color: #d89cc6 !important;
  padding: 0 3px;
  font-weight: bold;
}
.indicator {
  background-color: #ef8c35;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.indicator-outlined {
  border: 1px solid #ef8c35;
  color: #ef8c35 !important;
  padding: 0 3px;
  font-weight: bold;
}
.file {
  background-color: #ef8c35;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.tool {
  background-color: #5358a6;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.tool-outlined {
  border: 1px solid #5358a6;
  color: #5358a6 !important;
  padding: 0 3px;
  font-weight: bold;
}

.attack-pattern,
.sentence-entity {
  background-color: #377cb7;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}

.attack-pattern-outlined,
.sentence-entity-outlined {
  border: 1px solid #377cb7;
  color: #377cb7 !important;
  padding: 0 3px;
  font-weight: bold;
}

.vulnerability {
  background-color: #f7ca44;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}

.vulnerability-outlined {
  border: 1px solid #f7ca44;
  color: #f7ca44 !important;
  padding: 0 3px;
  font-weight: bold;
}
.campaign {
  background-color: #56bc8b;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.campaign-outlined {
  border: 1px solid #56bc8b;
  color: #56bc8b !important;
  padding: 0 3px;
  font-weight: bold;
}
.course-of-action {
  background-color: #8cc63f;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.course-of-action-outlined {
  border: 1px solid #8cc63f;
  color: #8cc63f !important;
  padding: 0 3px;
  font-weight: bold;
}
.infrastructure {
  background-color: #a1d7be;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.infrastructure-outlined {
  border: 1px solid #a1d7be;
  color: #a1d7be !important;
  padding: 0 3px;
  font-weight: bold;
}
.intrusion-set {
  background-color: #52b5c1;
  color: #ffffff !important;
  padding: 0 3px;
  font-weight: bold;
}
.intrusion-set-outlined {
  border: 1px solid #52b5c1;
  color: #52b5c1 !important;
  padding: 0 3px;
  font-weight: bold;
}

span.interset-left {
  padding-right: 0;
}
span.interset-right {
  padding-left: 0;
}
span.interset-node {
  padding: 0;
  border: 2px dotted #0e0d0dbb;
}

span.interset-node.interset-select {
  font-weight: bold;
  background-color: #f2f3cf;
  color: #333 !important;
}

.annotation,
.threat-actor,
.threat-actor-outlined,
.location,
.location-outlined,
.identity,
.identity-outlined,
.country,
.country-outlined,
.malware,
.malware-outlined,
.indicator,
.indicator-outlined,
.file,
.tool,
.tool-outlined,
.attack-pattern,
.attack-pattern-outlined,
.sentence-entity,
.sentence-entity-outlined,
.vulnerability,
.vulnerability-outlined,
.campaign,
.campaign-outlined,
.course-of-action,
.course-of-action-outlined,
.infrastructure,
.infrastructure-outlined,
.intrusion-set,
.intrusion-set-outlined {
  cursor: pointer;
}
